<template>
  <transition
    name="dialog-top-transition"
    appear
    mode="out-in"
  >
    <v-alert
      :model-value="modelValue"
      v-bind="safeAttrs"
      @update:model-value="$emit('update:modelValue', $event)"
    >
      <template #text>
        <slot name="default">
          <!--injected message goes here-->
        </slot>
      </template>
    </v-alert>
  </transition>
</template>

<script>
import { defaults } from 'lodash-es';

export default defineComponent({
  name: 'MtfAlert',
  inheritAttrs: true,
  customOptions: {},
  props: {
    modelValue: Boolean
  },
  emits: ['update:modelValue'],

  computed: {
    safeAttrs() {
      return defaults(
        { ...this.$attrs },
        {
          border: 'start',
          closable: true,
          closelLbel: 'Close',
          prominent: true,
          rounded: '0'
          // variant: 'tonal'
        }
      );
    }
  }
});
</script>
